import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Markdown from 'react-markdown';


const StyledProse = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    
    line-height: 2rem;
    
    margin-bottom: 60px;
    
    a {
      color: ${props => props.theme.project};
      text-decoration: none;
      
      :hover {
        opacity: 0.6;
      }
    }
`;


const Prose = ({ children }) => (
    <StyledProse>
        {children.split('\n').map((para, i) =>
            <p key={i}><Markdown>{para}</Markdown></p>
        )}
    </StyledProse>
);

Prose.propTypes = {
    children: PropTypes.string.isRequired,
};

Prose.defaultProps = {
    children: '',
};

export default Prose;
