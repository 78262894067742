import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import styled from 'styled-components';
import Image from '../Image';


const StyledNextProject = styled.div`
    border-radius: 8px;
    overflow: hidden;
    
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 8px;
    
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    
    margin-bottom: 64px;
`;


const NextProject = ({ slug, children }) => (
    <StyledNextProject>
        <Link to={`/projects/${slug}`}>
            {children}
        </Link>
    </StyledNextProject>
);

NextProject.propTypes = {
    slug: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

NextProject.defaultProps = {};

export default NextProject;
