import React from 'react'
import styled from 'styled-components';
import NextProject from '../components/Project/NextProject';
import Img from 'gatsby-image';
import VideoEmbed from '../components/Project/VideoEmbed';
import Seo from '../components/Seo';
import Layout from '../components/Layout'
import { Heading1, Heading2, Heading3 } from '../components/Project/Headings';
import Hero from '../components/Project/Hero';
import ImageWrapper from '../components/Project/ImageWrapper';
import Postcard from '../components/Project/Postcard';
import Prose from '../components/Project/Prose';


const PostcardContainer = styled.div`
    display: flex;
    justify-content: center;
`;


const COMPONENT_MAP = {
    Heading1: ({ text }) => <Heading1>{text}</Heading1>,
    Heading2: ({ text }) => <Heading2>{text}</Heading2>,
    Heading3: ({ text }) => <Heading3>{text}</Heading3>,
    Prose: ({ text }) => <Prose>{text}</Prose>,
    Image: ({ image }) => (
        <ImageWrapper full>
            <Img
                fluid={image.localFiles[0].childImageSharp.fluid}
            />
        </ImageWrapper>
    ),
    Video: ({ text, url }) => (
        <VideoEmbed src={url} title={text} />
    ),
    Postcard: () => (
        <PostcardContainer>
            <Postcard imageName="sunset"/>
        </PostcardContainer>
    ),
};


const Project = ({ data }) => {
    const { project, projectContent } = data;

    const projectContentData = projectContent.edges.map(edge => edge.node.data);

    return (
        <Layout projectColour={project.data.projectColour}>
            <Seo title={project.data.projectName} />

            <Hero
                title={project.data.heroTitle}
                blurb={project.data.heroBlurb}
            />

            {projectContentData.map(({ id, componentType, imageContent, textContent, urlContent }) => {
                const content = {
                    image: imageContent,
                    text: textContent,
                    url: urlContent,
                };

                const Component = COMPONENT_MAP[componentType];

                if (!Component) {
                    console.warn(`${componentType} doesn't match a component!`);
                    return null;
                }

                return (
                    <Component key={id} {...content} />
                );
            })}

            <NextProject slug={project.data.nextProject[0].data.slug}>
                <Img
                    fluid={project.data.nextProjectImage.localFiles[0].childImageSharp.fluid}
                />
            </NextProject>
        </Layout>
    );
};

export default Project;



export const pageQuery = graphql`
  query ProjectBySlug($projectSlug: String!) {
    project: airtable(table: {eq: "Projects"}, data: {slug: {eq: $projectSlug}}) {
      data {
        slug
        projectName: Project_Name
        projectColour: Primary_colour__button_
        heroTitle: Hero_title
        heroBlurb: Hero_blurb
        nextProject: Next_project {
            data {
              slug
            }
        }
        nextProjectImage: Next_project_image {
            localFiles {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
    projectContent: allAirtable(filter: {table: {eq: "Projects Content"}, data: {Ready_for_live: {eq: true}, Article: {elemMatch: {data: {slug: {eq: $projectSlug}}}}}}, sort: {fields: data___Order}) {
      edges {
        node {
          data {
            id: ID
            componentType: Component_type
            imageContent: Image_content {
              localFiles {
                childImageSharp {
                  # TODO: is it ok to demand 1440 always? 
                  fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            textContent: Text_content
            urlContent: URL_content
          }
        }
      }
    }
  }
`;
