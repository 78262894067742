import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImg from 'gatsby-image';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faHeart, faPaperPlane } from '@fortawesome/free-solid-svg-icons';


const Img = styled(GatsbyImg)``;

const Flipper = styled.div`
    transition: 0.7s;
    transform-style: preserve-3d;
    
    position: relative;
`;

const StyledPostcard = styled.div`
    width: 750px;
    height: 400px;
    
    perspective: 1000px;
    
    display: inline-block;
    margin: 1rem 0 60px 0;
    
    &:hover ${Flipper} {
      transform: rotateY(-180deg);
    }
`;

const Face = styled.div`
    backface-visibility: hidden;
    border: 1px solid #979797;
    
    position: absolute;
    top: 0;
    left: 0;
    
    height: 402px;
    width: 750px;
`;

const Front = styled(Face)`
    z-index: 2;
    
    /* for firefox 31 */
    transform: rotateY(0deg);
`;
const Back = styled(Face)`
    & ${Img} {
      filter: alpha(opacity=8);
      opacity: 0.08;
    }
    
    transform: rotateY(180deg);
`;

const Text = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px;
    text-align: justify;
    pointer-events: none;
    width: 540px;
    
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    
    & > span {
      display: inline-block;
      line-height: normal;
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
`;

const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: space-around;
    
    position: absolute;
    top: 0;
    left: 540px;
    
    width: 210px;
    height: 400px;
`;

const Button = styled.div`
    border-radius: 100%;
    color: #f65557;
    cursor: pointer;
    transition: 300ms;
    
    width: 76px;
    height: 76px;
    border: #f65557 4px solid;
    
    &:hover {
      background: #f65557;
      color: white;
    }
    
    display: flex;
    align-items: center;
    justify-content: center;
`;


const Postcard = ({ imageName, text }) => {
    const { images } = useStaticQuery(graphql`
        query {
            images: allFile(filter: { extension: { regex: "/jpe?g|png|gif/" } }) {
                nodes {
                    name
                    childImageSharp {
                        front: fixed(width: 750, height: 400) {
                            ...GatsbyImageSharpFixed
                        }
                        back: fixed(width: 540, height: 400) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `);

    const img = images.nodes.find(image => image.name === imageName);

    return (
        <StyledPostcard>
            <Flipper>
                <Front>
                    <Img fixed={img.childImageSharp.front}/>
                </Front>
                <Back>
                    <Img fixed={img.childImageSharp.back}/>
                    <Text>
                        <span>{text}</span>
                    </Text>
                    <Sidebar>
                        <Button>
                            <FontAwesomeIcon
                                icon={faBookmark}
                                style={{
                                    fontSize: "42px",
                                }}
                            />
                        </Button>
                        <Button>
                            <FontAwesomeIcon
                                icon={faHeart}
                                style={{
                                    fontSize: "48px",
                                    marginTop: "4px",
                                }}
                            />
                        </Button>
                        <Button>
                            <FontAwesomeIcon
                                icon={faPaperPlane}
                                style={{
                                    fontSize: "46px",
                                    marginRight: "6px",
                                }}
                            />
                        </Button>
                    </Sidebar>
                </Back>
            </Flipper>
        </StyledPostcard>
    );
};

Postcard.propTypes = {
    imageName: PropTypes.string.isRequired,
    text: PropTypes.string,
};

Postcard.defaultProps = {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et erat sit amet leo venenatis placerat in in orci. Aliquam non lacus volutpat, pharetra est vitae, aliquet ligula. Curabitur libero sapien, varius quis mollis a, maximus eu dolor. Phasellus urna nunc, blandit id volutpat sit amet, dapibus semper erat.',
};

export default Postcard;
