import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


export const Heading1 = styled.h1`
  font-size: 30px;
  font-weight: bold;
  
  margin-bottom: 40px;
`;

export const Heading2 = styled.h2`
  font-size: 26px;
  font-weight: bold;
  
  margin-bottom: 40px;
`;

export const Heading3 = styled.h3`
  font-size: 22px;
  font-weight: bold;
  
  margin-bottom: 40px;
`;