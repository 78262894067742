import React from 'react';

import styled from 'styled-components';


const StyledImage = styled.div`
    width: ${props => props.full ? '100vw' : '100%'};
    margin-left: ${props => props.full ? 'calc(-50vw + 50%)' : 0};
    margin-bottom: 64px;
    
    @media screen and (min-width: 1440px) {
      width: ${props => props.full ? '1440px' : '100%'};
      margin-left: ${props => props.full ? 'calc(-720px + 50%)' : 0};
    }
`;


// thx for full width https://stackoverflow.com/a/31391593/5022115

const Image = ({ full, children }) => (
    <StyledImage
        full={full}
    >
        {children}
    </StyledImage>
);

Image.propTypes = {};

Image.defaultProps = {};

export default Image;