import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledHero = styled.div`
    text-align: right;
    
    margin-bottom: 130px;
`;

const Title = styled.h1`
    font-size: 48px;
    font-weight: bold;
    
    color: ${props => props.theme.black};
`;

const Blurb = styled.h3`
    font-size: 32px;
    font-weight: normal;
    
    color: ${props => props.theme.darkGrey};
    
    margin-bottom: 0;
`;


const Hero = ({ title, blurb }) => (
    <StyledHero>
        <Title>{title}</Title>
        <Blurb>{blurb}</Blurb>
    </StyledHero>
);

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
