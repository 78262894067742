import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';


const StyledVideoEmbed = styled.div`
    margin-bottom: 40px;
`;


const VimeoVideo = ({ title, src }) => (
    <>
        <div style={{padding: '66.25% 0 0 0', position: 'relative'}}>
            <iframe
                src={src}
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                frameBorder="0"
                title={title}
                allow="autoplay; fullscreen"
                allowFullScreen
            />
        </div>
        <script src="https://player.vimeo.com/api/player.js"/>
    </>
);


const VideoEmbed = (props) => {
    const { src } = props;

    let VideoComponent;
    if (/player\.vimeo\.com/.test(src)) {
        VideoComponent = VimeoVideo;
    }

    if (!VideoComponent) {
        throw new Error('Unsupported video source!');
    }

    return (
        <StyledVideoEmbed>
            <VideoComponent {...props} />
        </StyledVideoEmbed>
    );
}

VideoEmbed.propTypes = {
    src: PropTypes.string.isRequired,
};

VideoEmbed.defaultProps = {};

export default VideoEmbed;
